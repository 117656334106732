import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SignUp from '../components/sign-up'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      family: file(relativePath: { eq: "images/family sm.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      young: file(relativePath:{ eq: "images/young.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout navClass="bg-secondary-600 white-links">
      <SEO
        title="Home"
      />
      <div className="overflow-x-hidden">
        <div className="bg-secondary-600 relative z-0">
          <div className="flex flex-wrap max-w-4xl mx-auto md:px-8 justify-between">
            <div className="w-full md:w-1/3 self-center text-white font-bold text-center md:text-left leading-tight py-12 md:py-20">
              <h1 className="text-3xl md:text-5xl mb-2">Join us on our journey to adopt</h1>
              <div className="pt-4">
                <Link className="btn text-base" to="/donate">Learn how</Link>
              </div>
            </div>
            <div className="w-full md:w-2/3 md:pl-8 flex items-end justify-end">
              <Img
                className="block w-full"
                alt="The Holle Family"
                fluid={data.family.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-center -mt-8 md:-mt-12 -mx-6 px-6 relative z-10">
          <div className="w-full sm:w-3/4 md:w-2/3 lg:w-1/2 px-6 mb-20">
            <SignUp />
          </div>
        </div>

        <div className="max-w-2xl mx-auto mb-32 px-6">
          <h2 className="font-bold text-3xl mb-4">Our Story</h2>
          <p>We are the Holles (pronounced Holly) and are inviting you to participate in as little or as much as you would like in our adoption story. This process is long, expensive and at times emotionally draining, but with your help there will be one less orphan in the world. </p>
          <figure className="float-left w-full sm:w-1/2 mb-8 sm:pr-8 sm:pb-0">
            <div className="shadow overflow-hidden rounded">
              <Img
                className="block"
                alt="Grant and Anna"
                fluid={data.young.childImageSharp.fluid}
              />
              <figcaption className="block bg-gray-100 text-sm text-gray-1000 px-4 py-2">Look at us... so young and full of life...</figcaption>
            </div>
          </figure>
          <p>A quick background on our family. As "high school sweethearts," we married in our Freshman year of college. Young and in love, we knew at some point we would be working overseas. Originally we thought we would help with the global humanitarian relief effort, but God had other plans. It was about six years into our marriage when we moved to China in 2014. After moving to China, we became parents for the first, second and third time. Three boys later and we quickly went from being a young couple without any children, to having a house full of robots, superheroes and runny noses. Through it all, we feel we have more love to give and more noses to wipe.</p>
          <p>It seems as though adoption was always going to be part of our story, but we never knew the logistics. Recently, those details have been revealed and we feel confident in moving forward with an adoption from Africa. </p>
          <p>Why Africa? There is no easy answer to this question. An orphan is an orphan no matter their race or ethnicity and we feel that all kids should have parents who will give them the unconditional love and security they deserve. For us, our heart for the needy is heavy. We ache when we see a child dying from drinking water or completely malnourished from starvation. The high ratio of orphans in Africa is our call to action. We know that this experience will not be easy, especially being an expat, but we are ready to fight for their cause. </p>
          <p>How can you help? We are only at the beginning of a long journey. It is no secret that adoption is very expensive so we are asking that you to not only join us in prayer, but also financially help support us. If you are a believer and feel called to help, or if adoption is dear to your heart, please consider being a part of this story. </p>
          <p className="text-secondary-900 font-bold">Grant, Anna, Bennett, Easton and Kylan</p>

          <p className="text-center">
            <Link className="btn text-base" to="/donate">Learn how to help</Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
